import classNames from 'classnames';
import { ProfileAddSvg, ProfileEditFilledSvg, ProfileKidsSvg, WebMenuAccountSvg } from '../../../exports/icons';
import { Media } from '../../Media';
import { AvatarVariant } from '../Avatar.types';
import { AvatarImageProps } from './AvatarImage.types';

/**
 * Displays the image of the Avatar component.
 */
export function AvatarImage({ label, isKids, isEdit, variant, isTv, image }: AvatarImageProps): JSX.Element {
  return (
    <>
      <div
        className={classNames(
          'flex items-center justify-center rounded-dt-radius-circle pointer-events-none relative w-full',
          {
            'tv-focus-parent:ring-fallback-4 tv-focus-parent:scale-104': isTv,
            'bg-dt-theme-background-avatar-avatar': variant === AvatarVariant.Add && !isTv,
            'bg-dt-theme-tv-background-avatar-avatar': variant === AvatarVariant.Add && isTv,
            'bg-dt-theme-background-avatar-avatar-profile': variant === AvatarVariant.Account,
          }
        )}
      >
        {variant === AvatarVariant.Default && image && (
          <Media
            className="w-full [&>div]:rounded-dt-radius-circle [&>div]:aspect-w-1 [&>div]:aspect-h-1"
            image={image}
            showTvFocusStyles={false}
          />
        )}
        {variant === AvatarVariant.Add && (
          <ProfileAddSvg
            className={classNames('h-full w-full scale-[0.8]', {
              'fill-dt-theme-icon-avatar-icon': !isTv,
              'fill-dt-theme-tv-icon-avatar-icon': isTv,
            })}
          />
        )}
        {variant === AvatarVariant.Account && (
          <WebMenuAccountSvg className="fill-dt-theme-icon-avatar-icon-profile h-full w-full scale-[0.8]" />
        )}
        {isKids && <ProfileKidsSvg className="bottom-0 absolute right-0 w-[25%]" />}
        {isEdit && <ProfileEditFilledSvg className="top-0 absolute right-0 w-[25%]" />}
      </div>
      {label && (
        <span
          className={classNames('block truncate font-dt-font-family-system text-center', {
            'text-dt-font-size-16 leading-dt-font-line-height-20 font-dt-font-weight-regular mt-dt-spacing-100 text-dt-theme-text-avatar-avatar':
              !isTv,
            'text-dt-font-size-30 leading-dt-font-line-height-36 font-dt-font-weight-regular mt-dt-spacing-150 text-dt-theme-tv-text-avatar-avatar tv-focus-parent:text-dt-theme-tv-text-avatar-avatar-focus':
              isTv,
          })}
        >
          {label}
        </span>
      )}
    </>
  );
}
