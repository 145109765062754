import { PROFILE } from '@canalplus/mycanal-commons';
import { HeaderProfileChildrenProps, ProfileSelector } from '@canalplus/mycanal-sharedcomponent';
import { Binder } from '@canalplus/one-navigation';
import classNames from 'classnames';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import IconRightArrow from '../../../assets/svg/rightArrow.svg';
import { useAppHistory } from '../../../helpers/hooks/reactRouter';
import { LAYER_PAGE } from '../../../helpers/oneNavigation/layers';
import { MIDDLEWARE_PROFILES_SELECTOR } from '../../../helpers/oneNavigation/middleware';
import { rewriteObjectUrls } from '../../../helpers/proxyUrls/proxyUrls';
import I18n from '../../../lang';
import { getFeatureToggleKidsProfiles } from '../../../selectors/application/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { profilesSelector } from '../../../store/slices/user-selectors';
import styles from './ProfileSelectorMyCanal.css';
import { useProfileSelector } from './hooks/useProfileSelector';

function ProfileSelectorMyCanal({ closeHeaderProfile = () => {} }: HeaderProfileChildrenProps): JSX.Element {
  const { t } = useContext(I18n.context);

  const featKidsProfiles = useSelector(getFeatureToggleKidsProfiles);
  const isTvDevice = useSelector(displayTVModeSelector);

  // To ensure that the URL of the userMenu avatar icon is properly proxied, we apply a guardrail as close as possible to the component
  const profiles = rewriteObjectUrls(useSelector(profilesSelector));

  const profilesList = !featKidsProfiles
    ? profiles.filter((profile) => profile.type === PROFILE && !profile.isKidsProfile)
    : profiles.filter((profile) => profile.type === PROFILE);

  const { handleProfileClick, handleEditButton, handleAddProfileClick, renderModals, closeProfilesModal } =
    useProfileSelector(LAYER_PAGE);

  const onClickEdit = useCallback(() => {
    handleEditButton();
    closeHeaderProfile();
  }, [handleEditButton, closeHeaderProfile]);

  const editButton = useMemo(
    () => (
      <button
        aria-label={t('ProfileManager.manage')}
        className={classNames(styles.ProfileSelectorMyCanal__editButton, 'profileSelectorEditButton')}
        data-e2e="profilesManage"
        onClick={onClickEdit}
        type="button"
        id="profileSelectorEditButton_onclick"
      >
        {t('ProfileManager.manage')}
        {isTvDevice ? <IconRightArrow className={styles.ProfileSelectorMyCanal__editButtonIcon} /> : null}
      </button>
    ),
    [onClickEdit, isTvDevice, t]
  );

  // Show only a profileSelector title on the tv context
  const profileSelectorTitle = isTvDevice ? (
    <div className={styles.ProfileSelectorMyCanal__profileSelectorTitle}>{t('ProfileManager.title')}</div>
  ) : null;

  // TODO: We need to delete this fix if we find better solution
  const history = useAppHistory();

  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        closeProfilesModal();
      }
    });
  }, [history, closeProfilesModal]);

  return (
    <>
      <Binder enabled={isTvDevice} middleware={MIDDLEWARE_PROFILES_SELECTOR}>
        <ProfileSelector
          customClass={styles.ProfileSelectorMyCanal}
          customClassProfileButton={styles.ProfileSelectorMyCanal__profileButton}
          customClassAddProfileButton={styles.ProfileSelectorMyCanal__addProfileButton}
          addProfileLabel={t('ProfileManager.add')}
          contents={profilesList}
          editButton={isTvDevice ? undefined : editButton}
          isTvDevice={isTvDevice}
          onAddProfileClick={handleAddProfileClick}
          onProfileClick={handleProfileClick}
          profileSelectorTitle={profileSelectorTitle}
          showControls={!isTvDevice}
          closeHeaderProfile={closeHeaderProfile}
        />
      </Binder>
      {isTvDevice && (
        <Binder enabled={isTvDevice} middleware={MIDDLEWARE_PROFILES_SELECTOR}>
          <div className={styles.ProfileSelectorMyCanal__editButtonWrapper}>{editButton}</div>
        </Binder>
      )}
      {renderModals()}
    </>
  );
}

export default ProfileSelectorMyCanal;
