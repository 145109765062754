import { Template } from '@canalplus/sdk-hodor';
import { ApiV2StrateContentButton } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import Linker from '../../../components/Linker/Linker';
import { useLogout } from '../../../helpers/pass/useLogout';
import { useSigninRedirect } from '../../../helpers/pass/useSigninRedirect';
import styles from './UserMenuMyCanalExtended.css';

export type UserMenuButtonsProps = {
  contents?: ApiV2StrateContentButton[];
  sectionTitle?: string;
  sectionSubtitle?: string;
};

function UserMenuButtons({ contents = [], sectionTitle, sectionSubtitle }: UserMenuButtonsProps): JSX.Element {
  const handleClick = useSigninRedirect();
  const logout = useLogout();

  return (
    <ul className={classNames(styles.UserMenuMyCanalExtended__buttons, 'UserMenuMyCanalExtended__buttons')}>
      {contents.map((content) => {
        switch (content.onClick?.displayTemplate) {
          case Template.Authentication:
            return (
              <li id={content.label} key={`button-content-${content.label}`}>
                <span className={classNames(styles.UserMenuMyCanalExtended__buttons__title)}>{sectionTitle}</span>
                <span className={classNames(styles.UserMenuMyCanalExtended__buttons__subtitle)}>{sectionSubtitle}</span>
                <button
                  className={classNames(
                    styles.UserMenuMyCanalExtended__buttons__button,
                    styles[`UserMenuMyCanalExtended__buttons__button--${content.style}`]
                  )}
                  id={`${content.label}_onclick`}
                  data-e2e="loginButton"
                  onClick={() => handleClick()}
                  type="button"
                >
                  {content.label}
                </button>
              </li>
            );
          case Template.Logout:
            return (
              <li id={content.label} key={`button-content-${content.label}`}>
                <button
                  className={classNames(
                    styles.UserMenuMyCanalExtended__buttons__button,
                    styles[`UserMenuMyCanalExtended__buttons__button--${content.style}`]
                  )}
                  id={`${content.label}_onclick`}
                  data-e2e="logoutButton"
                  onClick={logout}
                  type="button"
                >
                  {content.label}
                </button>
              </li>
            );
          default:
            return (
              <li id={content.label} key={`button-content-${content.label}`}>
                <Linker
                  className={styles.UserMenuMyCanalExtended__buttons__button}
                  data={{ mainOnClick: content.onClick }}
                  id={`${content.label}_onclick`}
                >
                  {content.label}
                </Linker>
              </li>
            );
        }
      })}
    </ul>
  );
}

export default UserMenuButtons;
