import { isClientSide } from '@canalplus/mycanal-commons';
import { getSigninUrl } from '@canalplus/sdk-pass';
import { usePassSdk } from '@canalplus/sdk-pass-react';
import { useSelector } from 'react-redux';
import { offerLocationSelector, offerZoneSelector } from '../../selectors/application/application-selectors';
import { isR7Available } from '../window/window-helper';

/** Redirect to the authentication page */
export const useSigninRedirect = (): ((redirectUri?: string) => void) => {
  const offerLocation = useSelector(offerLocationSelector);
  const offerZone = useSelector(offerZoneSelector);

  const signinUrl = usePassSdk(getSigninUrl);

  return (redirectUri = isClientSide() ? window.location.href : '') => {
    if (isR7Available()) {
      window.R7('loginUser', {
        offerZone,
        offerLocation,
      });

      return;
    }

    window.location.assign(signinUrl({ redirectUri }));
  };
};
