import { getParentalCodeStatus, getPurchaseCodeStatus, isAcmApiError } from '@canalplus/sdk-acm';
import { PlatformGroup } from '@canalplus/sdk-core';
import { ParentalCodeStatusValue, PurchaseCodeStatusValue } from '@canalplus/types-acm';
import { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { getOneCoreParentalCodeExist } from '../../../helpers/adult/adult-helper';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import Logger from '../../../helpers/logger/logger-helper';
import {
  offerLocationSelector,
  offerZoneSelector,
  platformGroupSelector,
} from '../../../selectors/application/application-selectors';
import { getAcmSdkConfig } from '../../../services/Acm/getAcmSdkConfig';
import { updateAdultStatus } from '../../../store/slices/adult';
import { isOneCoreParentalCodeSelector } from '../../../store/slices/adult-selectors';
import { updatePurchaseCodeStatus } from '../../../store/slices/purchaseCode';
import { authenticatedSelector, passTokenSelector } from '../../../store/slices/user-selectors';
import type { IState } from '../../../store/types/State-type';

/**
 * To be used when:
 * - User is authenticated
 * - the passToken is available
 */
export const useAcmCinematic = (): void => {
  const state = useStore<IState>().getState();
  const dispatch = useAppDispatch();

  const isAuthenticated = useSelector(authenticatedSelector);
  const offerLocation = useSelector(offerLocationSelector);
  const offerZone = useSelector(offerZoneSelector);
  const passToken = useSelector(passTokenSelector);
  const platformGroup = useSelector(platformGroupSelector);

  useEffect(() => {
    (async function performAcmCinematic() {
      if (isAuthenticated && passToken) {
        const config = getAcmSdkConfig({ state });

        if (isOneCoreParentalCodeSelector(state)) {
          // get parental code information from oneCore
          try {
            const { exists } = await getOneCoreParentalCodeExist();

            dispatch(updateAdultStatus(exists));
          } catch (error) {
            Logger.error(`Acm SafetyCode: ${error}`, {
              offerLocation,
              offerZone,
              errorMessage: isAcmApiError(error) ? error.errorDescription : error,
              errorCode: isAcmApiError(error) ? error.errorCode : '500',
            });
          }
        } else {
          let parentalCodeStatusValue;

          try {
            parentalCodeStatusValue = (await getParentalCodeStatus({ ...config, passToken }))?.status?.value;
          } catch {
            // Prevent bubbling error to allow user to consume allowed streams
          }

          // UPDATE Parental code Status depending on ACM Status Value
          switch (parentalCodeStatusValue) {
            case ParentalCodeStatusValue.NotSetup:
              dispatch(updateAdultStatus(false));
              break;

            case ParentalCodeStatusValue.Activated:
            case ParentalCodeStatusValue.LegalActivated:
              dispatch(updateAdultStatus(true));
              break;

            default:
              break;
          }
        }

        if (platformGroup !== PlatformGroup.Orange) {
          let purchaseCodeStatusValue;

          try {
            purchaseCodeStatusValue = (await getPurchaseCodeStatus({ ...config, passToken }))?.status?.value;
          } catch {
            // Prevent bubbling error to allow user to consume allowed streams
          }

          // UPDATE Purchase code Status depending on ACM Status Value
          switch (purchaseCodeStatusValue) {
            case PurchaseCodeStatusValue.Disabled:
              dispatch(updatePurchaseCodeStatus({ disabled: true, enabled: false }));
              break;

            case PurchaseCodeStatusValue.NotSetup:
              dispatch(updatePurchaseCodeStatus({ disabled: false, enabled: false }));
              break;

            case PurchaseCodeStatusValue.Activated:
              dispatch(updatePurchaseCodeStatus({ disabled: false, enabled: true }));
              break;

            default:
              break;
          }
        }
      }
    })();
  }, [passToken, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps
};
