import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { setCookieWhoIsWatchingSession } from '../../../helpers/whoIsWatching/cookieHelper';
import {
  getFeatureToggleWhoIsWatching,
  getWhoIsWatchingUserSettingsSelector,
  isMobileSelector,
  isSafariSelector,
  shouldDisplayWhoIsWatchingSelector,
  userSelector,
} from '../../../selectors/application/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';

type UseWhoIsWatching = {
  /**
   * Indicate if the screen WhoIsWatching is displayed or not
   */
  showWhoIsWatching: boolean;
};

/**
 * Hook that indicate if WhoIsWatching screen at startup have to be displayed.
 * It manages too the set of the session cookie WhoIsWatching just before leave the app.
 * WhoIsWatching screen is displayed if:
 * - User is authenticated
 * - The feature WhoIsWatching is enabled (featureToggleWhoIsWatching in settings)
 * - WhoIsWatching is not disabled in preference user
 * - WhoIsWatching is not displayed for a certain period of time (whoIsWatchingSession)
 * @returns object UseWhoIsWatching
 */
export const useWhoIsWatching = (): UseWhoIsWatching => {
  const featureToggleWhoIsWatching = useSelector(getFeatureToggleWhoIsWatching);
  const shouldDisplayWhoIsWatching = useSelector(shouldDisplayWhoIsWatchingSelector);
  const whoIsWatchingUserSettings = useSelector(getWhoIsWatchingUserSettingsSelector);
  const isMobile = useSelector(isMobileSelector);
  const isSafari = useSelector(isSafariSelector);
  const isMobileSafari = isMobile && isSafari;
  const user = useSelector(userSelector);
  const isTvDevice = useSelector(displayTVModeSelector);

  const [showWhoIsWatching, setShowWhoIsWatching] = useState(false);

  /**
   * At startup only, check if we have to display the whoIsWatching screen
   */
  useEffect(
    () => {
      if (featureToggleWhoIsWatching && shouldDisplayWhoIsWatching) {
        setShowWhoIsWatching(true);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Listen change on whoIsWatchingUserSettings.inSession to hide the whoIsWatching screen
   * inSession become true when user select a profile on the whoIsWatching screen (see WhoIsWatching.tsx)
   */
  useEffect(() => {
    if (whoIsWatchingUserSettings.inSession) {
      setShowWhoIsWatching(false);
    }
  }, [whoIsWatchingUserSettings]);

  /**
   * Set the session cookie WhoIsWatching just before leave the app if:
   * - Feature WhoIsWatching is enabled (featureToggleWhoIsWatching)
   * - WhoIsWatching is not disabled by user settings
   * - Recommandations is enabled
   * We set the cookie just before leave the app and not at startup to avoid display whoIsWatching screen
   * when reload the app (switch profile, activate/desactivate recommandations, refresh rights, accept or update consent)
   */
  const onBeforeUnload = useMemo(
    () => () => {
      if (featureToggleWhoIsWatching && !whoIsWatchingUserSettings.disabled && user.reco) {
        setCookieWhoIsWatchingSession(isTvDevice);
      }

      return undefined;
    },
    [isTvDevice, featureToggleWhoIsWatching, whoIsWatchingUserSettings, user]
  );

  useEffect(() => {
    window.addEventListener(isMobileSafari ? 'pagehide' : 'beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener(isMobileSafari ? 'pagehide' : 'beforeunload', onBeforeUnload);
    };
  }, [isMobileSafari, onBeforeUnload]);

  return { showWhoIsWatching };
};
