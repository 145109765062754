import { generatePassToken, isPassTokenExpired } from '../../helpers/user/user-helper';
import { getVisibilityChange } from '../../helpers/window/window-helper';
import { getPassToken } from '../../store/slices/user';
import { authenticatedSelector, passTokenCreationDateSelector } from '../../store/slices/user-selectors';

export function checkPassTokenValidity(store: Redux.CustomStore): void {
  // Check if authenticated user needs reauthentication after inactivity
  // When user focuses on app or on browser tab, we check if the passToken is still valid
  const checkTokenValidity = async () => {
    const state = store.getState();
    const authenticated = authenticatedSelector(state);
    const passTokenCreationDate = passTokenCreationDateSelector(state);

    if (document.hasFocus() && authenticated && isPassTokenExpired(passTokenCreationDate)) {
      // persist the new pass token into the store
      const newPassToken = await generatePassToken(state);
      store.dispatch(getPassToken(newPassToken));
    }
  };

  // Get name of visibilityChange event depending on browser
  const visibilityChange = getVisibilityChange();

  // gets fired when user switches tabs inside the browser window
  if (visibilityChange) {
    document.addEventListener(visibilityChange, checkTokenValidity);
  }

  // gets fired when the browser is on focus
  window.addEventListener('focus', checkTokenValidity);
}
