import classNames from 'classnames';
import { DropdownMenuItem } from '../../Dropdown/DropdownMenuItem';
import HeaderNavDropdownItem from '../HeaderNavDropdownItem/HeaderNavDropdownItem';
import { HeaderNavDropdownMenuProps } from './HeaderNavDropdownMenu.types';

/**
 * A `HeaderNavDropdownMenu` is a dropdown menu component for the `HeaderNavDropdown`. It is used to display
 * a menu `<ul>` of items `<li>` vertically.
 * @param items List of items to display in the menu
 * @param currentIndex Index of the active item, meaning current page (-1 if no item is active)
 * @returns Menu of items to be displayed in the dropdown
 */
export function HeaderNavDropdownMenu({ items, currentIndex = -1 }: HeaderNavDropdownMenuProps): JSX.Element {
  const resetUl = 'list-none m-dt-spacing-none p-dt-spacing-none';
  return (
    <ul
      className={classNames(
        resetUl,
        'bg-dt-theme-surface-profile-card-profile-card rounded-dt-radius-large py-dt-spacing-100 shadow-card w-max outline-none'
      )}
    >
      {items?.map((item, i) => (
        <li role="none" key={`navlist-dropdownmenu-${item.label}-${item.id}`}>
          <DropdownMenuItem
            label={item.label}
            onClick={item.onClick}
            renderComponent={({ isActive, itemProps }) => (
              <HeaderNavDropdownItem isCurrent={currentIndex === i} isFocused={isActive} {...item} {...itemProps} />
            )}
          />
        </li>
      ))}
    </ul>
  );
}
