import { cva } from 'class-variance-authority';

export const navItemCVA = cva('group', {
  variants: {
    current: {
      true: undefined,
      false: undefined,
    },
    device: {
      tv: '[&]:h-[3.875rem] [&]:rounded-dt-radius-xlarge',
      web: '[&]:h-38 [&]:px-dt-spacing-100',
    },
  },
  compoundVariants: [
    {
      current: true,
      device: 'tv',
      className: '[&]:bg-dt-theme-background-header-header-active',
    },
    {
      current: false,
      device: 'tv',
      className: '[&]:bg-transparent',
    },
  ],
});

export const navItemIconCVA = cva('', {
  variants: {
    current: {
      true: undefined,
      false: undefined,
    },
    device: {
      tv: undefined,
      web: undefined,
    },
  },
  compoundVariants: [
    {
      current: false,
      device: 'web',
      className: '[&]:fill-dt-theme-text-header-header-default group-hover:[&]:fill-dt-theme-text-header-header-active',
    },
    {
      device: 'web',
      className: '[&]:transition-colors [&]:duration-200',
    },
  ],
});

export const navItemLabelCVA = cva('', {
  variants: {
    current: {
      true: undefined,
      false: undefined,
    },
    device: {
      tv: '[&]:text-dt-font-size-32 [&]:leading-dt-font-line-height-38 [&]:font-dt-font-weight-semibold',
      web: '[&]:text-dt-font-size-16 [&]:leading-dt-font-line-height-20 [&]:font-dt-font-weight-semibold',
    },
  },
  compoundVariants: [
    {
      current: true,
      device: 'web',
      className: '[&]:text-dt-theme-text-header-header-active',
    },
    {
      current: false,
      device: 'web',
      className: '[&]:text-dt-theme-text-header-header-default group-hover:[&]:text-dt-theme-text-header-header-active',
    },
    {
      current: true,
      device: 'tv',
      className: '[&]:text-dt-theme-text-header-header-default',
    },
    {
      device: 'web',
      className: '[&]:transition-colors [&]:duration-200',
    },
  ],
});
